@import '../color.scss';
@import '../assets/fonts/fonts.scss';

.home_container {
    background-color: $red_semi;

    .carousel_container {
        background-color: $red_semi;
        position: relative;
        // padding: 2%;
        z-index: 3;
        flex: 1;
        height: calc(100vh - 120px);

        @media screen and (max-width: 1440px) {
            height: calc(100vh - 120px);
        }

        @media screen and (max-width: 1200px) {
            height: 450px;
        }

        @media screen and (max-width: 991px) {
            height: 430px;
        }

        @media screen and (max-width: 768px) {
            height: 350px;
        }

        @media screen and (max-width: 576px) {
            height: 320px;
        }

        .banner_carousel {
            height: 100%;

            div {
                height: 100% !important;
            }

            .css-1f8sh1y {
                height: 100% !important;
            }

            .MuiPaper-root {
                // background-color: transparent;
                box-shadow: none;
                border-radius: 0px;

                .banner_img {
                    img {
                        width: 100%;
                        height: calc(100% + 98px);

                        // filter: grayscale(100);
                        @media screen and (max-width: 1366px) {
                            height: calc(100% + 89px);
                        }

                        @media screen and (max-width: 1279px) {
                            height: calc(100% + 79px);
                        }

                        @media screen and (max-width: 1023px) {
                            height: calc(100% + 70px);
                        }

                        @media screen and (max-width: 576px) {
                            height: calc(100% + 0px);
                            transform: scale(1.5);
                        }
                    }
                }
            }

            // .css-1abc02a {
            //     z-index: 10;

            //     .MuiButtonBase-root {
            //         opacity: 1;
            //         width: 40px;
            //         height: 40px;
            //         border: 1px solid $white;
            //         background-color: $red;
            //         margin: 0px 23px;

            //         @media screen and (max-width: 1023px) {
            //             width: 35px;
            //             height: 35px;
            //         }

            //         @media screen and (max-width: 575px) {
            //             width: 25px;
            //             height: 25px;
            //         }

            //         .MuiSvgIcon-root {
            //             width: 15px;
            //             height: 15px;

            //             @media screen and (max-width: 1023px) {
            //                 width: 13px;
            //                 height: 13px;
            //             }

            //             @media screen and (max-width: 575px) {
            //                 width: 11px;
            //                 height: 11px;
            //             }
            //         }
            //     }
            // }

            // .css-hn784z {
            //     z-index: 10;

            //     .MuiButtonBase-root {
            //         opacity: 1;
            //         width: 40px;
            //         height: 40px;
            //         border: 1px solid $white;
            //         background-color: $red;
            //         margin: 0px 23px;

            //         @media screen and (max-width: 1023px) {
            //             width: 35px;
            //             height: 35px;
            //         }

            //         @media screen and (max-width: 575px) {
            //             width: 25px;
            //             height: 25px;
            //         }

            //         .MuiSvgIcon-root {
            //             width: 15px;
            //             height: 15px;

            //             @media screen and (max-width: 1023px) {
            //                 width: 13px;
            //                 height: 13px;
            //             }

            //             @media screen and (max-width: 575px) {
            //                 width: 11px;
            //                 height: 11px;
            //             }
            //         }
            //     }
            // }

            // .css-1m9128y {
            //     z-index: 10;
            //     position: absolute;
            //     bottom: 20px;
            //     margin: 0px;
            //     height: unset !important;

            //     @media screen and (max-width: 1023px) {
            //         bottom: 14px;
            //     }

            //     .MuiButtonBase-root {
            //         height: 12px;
            //         width: 12px;
            //         margin: 0px 1px;

            //         .MuiSvgIcon-root {
            //             fill: $white;
            //             width: 100%;
            //             height: 100%;
            //             font-size: 12px;
            //         }

            //         &.css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
            //             .MuiSvgIcon-root {
            //                 fill: $red;
            //             }
            //         }
            //     }
            // }
        }

        .carousel_overlay {
            margin-left: 0;
            margin-top: 0;
            position: absolute;
            top: 0;
            z-index: 5;
            left: 50;
            width: 100%;
            height: 100%;
            display: flex;

            // background-color: rgba(217, 23, 23, 0.6);
            // pointer-events: none;
            @media screen and (max-width: 991px) {
                display: block;
            }

            .overlay_carousel {
                width: 40%;
                margin-left: 100px;
                align-self: center;

                @media screen and (max-width: 1100px) {
                    width: 50%;
                }

                @media screen and (max-width: 991px) {
                    width: 60%;
                    margin-top: 90px;
                    margin-left: 90px;
                    height: 45%;
                }

                @media screen and (max-width: 768px) {
                    width: 70%;
                    margin-top: 60px;
                    margin-left: auto;
                    margin-right: auto;
                    height: 45%;
                }

                @media screen and (max-width: 576px) {
                    width: 70%;
                    margin-top: 60px;
                    margin-left: auto;
                    margin-right: auto;
                    height: 40%;
                }

                .overlay_text {
                    .overlay_head {
                        max-width: 100%;

                        .MuiTypography-root {
                            font-size: 2.5em;
                            font-family: GillSans-Medium;
                            color: $white;

                            @media screen and (max-width: 768px) {
                                font-size: 2.0em;
                            }

                            @media screen and (max-width: 576px) {
                                font-size: 1.4em;
                            }
                        }
                    }

                    .overlay_body {
                        max-width: 95%;

                        .MuiTypography-root {
                            font-size: 1.2em;
                            font-family: GillSans-Light;
                            color: $white;

                            @media screen and (max-width: 768px) {
                                font-size: 1em;
                            }

                            @media screen and (max-width: 576px) {
                                font-size: 0.9em;
                            }
                        }
                    }
                }

            }
        }
    }

    .Product_container {
        .Product_header {
            text-align: center;
            padding: 1%;

            .MuiTypography-root {
                color: $red;
                font-size: 2.0em;
                font-family: GillSans-Italic;

                @media screen and (max-width:576px) {
                    font-size: 1.8em;
                }
            }
        }

        .Product_display {
            background-color: $white;
        }
    }

    .Coco_container {
        .Coco_title {
            text-align: center;
            padding: 1%;

            .MuiTypography-root {
                color: $red;
                font-size: 2.0em;
                font-family: GillSans-Italic;

                @media screen and (max-width:576px) {
                    font-size: 1.8em;
                }
            }
        }

        .Coco_description {
            background-image: linear-gradient(to right, rgba(16, 77, 87, 0), rgba(16, 77, 87, 1));
            display: flex;
            flex-direction: row;

            @media screen and (max-width:768px) {
                background-image: linear-gradient(to right, rgba(16, 77, 87, 0), rgba(16, 77, 87, 0.3));
                flex-direction: column;
            }

            @media screen and (max-width:576px) {
                flex-direction: column;
            }

            .Coco_left {
                background-image: linear-gradient(to right, rgba(16, 77, 87, 0), rgba(16, 77, 87, 0));
                display: flex;
                width: 50%;
                padding: 5%;
                text-align: justify;

                @media screen and (max-width:768px) {
                    width: 100%;
                }

                @media screen and (max-width:576px) {
                    width: 100%;
                }

                .MuiTypography-root {
                    color: $red;
                    font-size: 1.4em;
                    font-family: GillSans-Italic;

                    @media screen and (max-width:576px) {
                        font-size: 1.2em;
                    }

                    span {
                        font-size: 1.3em;
                        font-family: GillSans-MediumItalic;
                        color: $red;
                    }
                }
            }

            .Coco_right {
                display: flex;
                width: 50%;
                padding: 5%;
                background-image: linear-gradient(to right, rgba(16, 77, 87, 0.5), rgba(16, 77, 87, 1));
                text-align: justify;

                @media screen and (max-width:768px) {
                    width: 100%;
                }

                @media screen and (max-width:576px) {
                    width: 100%;
                }

                .MuiTypography-root {
                    color: $white;
                    font-size: 1.4em;
                    font-family: GillSans-Italic;

                    @media screen and (max-width:576px) {
                        font-size: 1.2em;
                    }
                }
            }
        }
    }

    .Speciality_container {
        .Speciality_title {
            text-align: center;
            padding: 1%;

            .MuiTypography-root {
                color: $red;
                font-size: 2.0em;
                font-family: GillSans-Italic;

                @media screen and (max-width:576px) {
                    font-size: 1.8em;
                }
            }
        }

        .Speciality_description1 {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            background-color: $white;

            @media screen and (max-width:768px) {
                flex-direction: column;
            }

            @media screen and (max-width:576px) {
                flex-direction: column;
            }

            .sp_card {
                display: flex;
                flex-direction: column;
                width: 23%;

                // padding: 5%;
                @media screen and (max-width:768px) {
                    width: 100%;
                }

                @media screen and (max-width:576px) {
                    width: 100%;
                }

                .sp_img {
                    display: flex;
                    justify-content: center;

                    // padding: 25%;
                    @media screen and (max-width:576px) {
                        padding: 0;
                    }

                    .img_vector {
                        height: 150px;
                        width: 150px;
                        padding: 5%;

                        @media screen and (max-width: 1440px) {
                            height: 150px;
                            width: 150px;
                        }

                        @media screen and (max-width: 991px) {
                            padding: 2%;
                            height: 120px;
                            width: 120px;
                        }

                        @media screen and (max-width: 768px) {
                            padding: 2%;
                            height: 100px;
                            width: 100px;
                        }

                        @media screen and (max-width: 576px) {
                            padding: 2%;
                            height: 90px;
                            width: 90px;
                        }
                    }
                }

                .sp_content {
                    display: flex;
                    text-align: center;

                    // padding-bottom: 10%;
                    @media screen and (max-width: 768px) {}

                    @media screen and (max-width: 576px) {
                        padding: 2%;
                    }

                    .MuiTypography-root {
                        font-family: GillSans;
                        font-size: 1.1em;

                        span {
                            font-family: GillSans-Medium;
                            font-weight: bold;
                            font-size: 1.12em;
                        }
                    }
                }
            }
        }

        .Speciality_description2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            background-color: $white;

            @media screen and (max-width:768px) {
                flex-direction: column;
            }

            @media screen and (max-width:576px) {
                flex-direction: column;
            }

            .sp_card {
                display: flex;
                flex-direction: column;
                width: 23%;

                // padding: 5%;
                @media screen and (max-width:768px) {
                    width: 100%;
                }

                @media screen and (max-width:576px) {
                    width: 100%;
                }

                .sp_img {
                    display: flex;
                    justify-content: center;

                    // padding: 25%;
                    @media screen and (max-width:576px) {
                        padding: 0;
                    }

                    .img_vector {
                        height: 130px;
                        width: 130px;
                        padding: 5%;

                        @media screen and (max-width: 1440px) {
                            height: 130px;
                            width: 130px;
                        }

                        @media screen and (max-width: 991px) {
                            padding: 2%;
                            height: 120px;
                            width: 120px;
                        }

                        @media screen and (max-width: 768px) {
                            padding: 2%;
                            height: 100px;
                            width: 100px;
                        }

                        @media screen and (max-width: 576px) {
                            padding: 2%;
                            height: 90px;
                            width: 90px;
                        }
                    }
                }

                .sp_content {
                    display: flex;
                    text-align: center;

                    // padding-bottom: 10%;
                    @media screen and (max-width: 768px) {}

                    @media screen and (max-width: 576px) {
                        padding: 2%;
                    }

                    .MuiTypography-root {
                        font-family: GillSans;
                        font-size: 1.1em;

                        span {
                            font-family: GillSans-Medium;
                            font-weight: bold;
                            font-size: 1.12em;
                        }
                    }
                }
            }
        }
    }

    .Testimonials {
        position: relative;
        flex: 1;
        flex-direction: row;
        z-index: 3;

        .react-multi-carousel-item {
            display: flex;
            justify-content: center;
            padding-top: 2%;
            padding-bottom: 2%;

            .MuiPaper-root {
                .MuiButtonBase-root {
                    .MuiCardContent-root {
                        background-color: $red_semi;

                        .MuiTypography-root {
                            font-family: GillSans;
                            font-size: 1.3em;

                            @media screen and (max-width:576px) {
                                font-size: 1.2em;
                            }
                        }
                    }
                }
            }
        }
    }
}