@import './color.scss';

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: $red;
    border-radius: 20px;
    border: 4px solid transparent;
}

::-webkit-scrollbar-track {
    background-color: $light;
    border-radius: 20px;
}