$white: #FFFFFF;
$black: #000;
$dark_red:#1c0505;
$red: #104d57;
$red_semi: #e1f0f2f1;
$red_2: #096868;
$light_red: #d47a7a;
$light:#f8e0e0;
$grey:#5b5a5a;
$light_grey:#f7f3f3;
$dark_grey:#3f3e3e;