@import '../color.scss';
@import '../assets/fonts/fonts.scss';

.product-main {
    display: flex;
    flex-direction: row;

    @media screen and (max-width:768px) {
        flex-direction: column;
    }

    .product-image {
        display: flex;
        width: 50%;
        z-index: 3;
        position: relative;

        @media screen and (max-width:768px) {
            width: 100%;
        }

        .product_carousel {
            width: 100%;

            .MuiPaper-root {
                // background-color: transparent;
                box-shadow: none;
                border-radius: 0px;

                .product_img {
                    display: flex;
                    justify-content: center;

                    img {
                        height: calc(100vh - 120px);

                        @media screen and (max-width:768px) {
                            height: 400px;
                        }
                    }
                }
            }

            .css-1abc02a {
                z-index: 10;

                .MuiButtonBase-root {
                    opacity: 1;
                    width: 40px;
                    height: 40px;
                    border: 1px solid $white;
                    background-color: $red;
                    margin: 0px 23px;

                    @media screen and (max-width: 1023px) {
                        width: 35px;
                        height: 35px;
                    }

                    @media screen and (max-width: 575px) {
                        width: 25px;
                        height: 25px;
                    }

                    .MuiSvgIcon-root {
                        width: 15px;
                        height: 15px;

                        @media screen and (max-width: 1023px) {
                            width: 13px;
                            height: 13px;
                        }

                        @media screen and (max-width: 575px) {
                            width: 11px;
                            height: 11px;
                        }
                    }
                }
            }

            .css-hn784z {
                z-index: 10;

                .MuiButtonBase-root {
                    opacity: 1;
                    width: 40px;
                    height: 40px;
                    border: 1px solid $white;
                    background-color: $red;
                    margin: 0px 23px;

                    @media screen and (max-width: 1023px) {
                        width: 35px;
                        height: 35px;
                    }

                    @media screen and (max-width: 575px) {
                        width: 25px;
                        height: 25px;
                    }

                    .MuiSvgIcon-root {
                        width: 15px;
                        height: 15px;

                        @media screen and (max-width: 1023px) {
                            width: 13px;
                            height: 13px;
                        }

                        @media screen and (max-width: 575px) {
                            width: 11px;
                            height: 11px;
                        }
                    }
                }
            }

            .css-1m9128y {
                z-index: 10;
                position: absolute;
                bottom: 20px;
                margin: 0px;
                height: unset !important;

                @media screen and (max-width: 1023px) {
                    bottom: 14px;
                }

                .MuiButtonBase-root {
                    height: 12px;
                    width: 12px;
                    margin: 0px 1px;

                    .MuiSvgIcon-root {
                        fill: $white;
                        width: 100%;
                        height: 100%;
                        font-size: 12px;
                    }

                    &.css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
                        .MuiSvgIcon-root {
                            fill: $red;
                        }
                    }
                }
            }
        }

    }

    .product-description {
        display: flex;
        width: 50%;
        flex-direction: column;
        // background-color: $red_semi;
        padding: 5%;

        @media screen and (max-width:768px) {
            width: 100%;
        }

        .product-title {
            display: flex;
            width: 100%;

            .MuiTypography-root {
                font-family: GillSans;
                font-size: 1.6em;

                @media screen and (max-width:768px) {
                    font-size: 1.2em;
                }
            }

            span {
                color: rgb(203, 17, 17);
            }

            .mrp {
                font-size: 0.7em;
                text-decoration: line-through;
            }
        }

        .product-details {
            display: flex;
            width: 100%;
            flex-direction: column;

            .product-details-header {
                display: flex;
                justify-content: center;

                .MuiTypography-root {
                    font-family: GillSans;
                    // text-decoration: underline;
                    font-size: 1.6em;
                    color: $red;

                    @media screen and (max-width:768px) {
                        font-size: 1.2em;
                    }
                }
            }

            .product-details-body {
                display: flex;
                flex-direction: column;

                .product-descript {
                    display: flex;
                    flex-direction: row;

                    .parameter {
                        display: flex;
                        width: 50%;
                        font-family: GillSans-Medium;
                        font-size: 1.1em;
                    }

                    .values {
                        display: flex;
                        width: 50%;
                        font-family: GillSans;
                    }
                }

                img {
                    height: 150px;
                    width: 200px;
                }

            }

            .product-details-tail {
                display: flex;
                flex-direction: column;
                .about-item {
                    display: flex;
                    padding:5% 0% 5% 0%;
                    .MuiTypography-root {
                        font-family: GillSans;
                        font-size: 1.0em;
                        span{
                            font-size: 1.2em;
                            color: $red;
                        }
                    }
                }
                .caution {
                    display: flex;
                    .MuiTypography-root {
                        font-family: GillSans;
                        font-size: 1.1em;
                        span {
                            color: rgb(203, 17, 17);
                        }
                    }
                }

            }
        }

    }
}