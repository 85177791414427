@import '../color.scss';
@import '../assets/fonts/fonts.scss';

.footer_container{
    background-color: $red;
    .MuiBox-root{
        .footer_divider{
            background-color: $red_semi;
            margin: 1px 15px;
        }
        .top_container{
            .top_inner{
                display: flex;
                justify-content: space-between;
                .title_container{
                    display: flex;
                    padding: 1%;
                    margin-left: 60px;
                    @media screen and (max-width: 1200px) {
                        margin-left: 45px;
                    }
                    @media screen and (max-width: 992px) {
                        margin-left: 30px;
                    }
                    @media screen and (max-width: 768px) {
                        margin-left: 20px;
                    }
                    @media screen and (max-width: 576px) {
                        margin-left: 10px;
                    }
                    .footer_icon{
                        width: 55px;
                        max-height: 55px;
                        border-radius: 10px;
                        // @media screen and (max-width: 768px) {
                        //     width: 32px;
                        //     max-height: 32px;
                        // }
                        // @media screen and (max-width: 576px) {
                        //     width: 27px;
                        //     max-height: 27px;
                        // }
                        @media screen and (max-width: 300px) {
                            width: 30px;
                            max-height: 30px;
                        }
                    }
                    .footer_title{
                        color: $white;
                        margin-left: 20px;
                        margin-top: 10px;
                        font-size: 1.6em;
                        font-family: GillSans;
                        @media screen and (max-width: 768px) {
                            font-size: 1.4em;
                        }
                        @media screen and (max-width: 576px) {
                            padding-top:5px ;
                            font-size: 1.2em;
                        }
                        @media screen and (max-width: 450px) {
                            padding-top:10px ;
                            font-size: 0.9em;
                        }
                    }
                }
                .social_stack{
                    margin-right: 130px;
                    @media screen and (max-width: 1200px) {
                        margin-right: 100px;
                    }
                    @media screen and (max-width: 992px) {
                        margin-right: 65px;
                    }
                    @media screen and (max-width: 768px) {
                        margin-right: 35px;
                    }
                    @media screen and (max-width: 576px) {
                        margin-right: 20px;
                    }
                    .MuiLink-root{
                        margin-right: 35px;
                        @media screen and (max-width: 992px) {
                            margin-right: 25px;
                        }
                        @media screen and (max-width: 768px) {
                            margin-right: 20px;
                        }
                        @media screen and (max-width: 576px) {
                            margin-right: 15px;
                        }
                    }
                }
            }
            
        }
        .bottom_container{
            display: flex;
            justify-content: space-evenly;
            @media screen and (max-width: 670px) {
                display: block;
                // text-align: center;
            }
            .list_container{
                margin: 20px 40px;
                @media screen and (max-width: 670px) {
                    display: block;
                    margin: 20px auto;
                }
                .list_title{
                    font-family: GillSans-Medium;
                    font-size: 0.9em;
                    color: $red_semi;
                    @media screen and (max-width: 670px) {
                        text-align: center;
                    }
                }
                .MuiList-root{
                    @media screen and (max-width: 670px) {
                        display: block;
                    }
                    
                    .MuiListItem-root{
                        padding: 1px;
                        flex-grow: unset;
                         -webkit-flex-grow: unset;
                        @media screen and (max-width: 670px) {
                            justify-content: center;
                        }
                        .MuiListItemText-root{
                            margin: 0;
                            -webkit-flex: unset;
                            flex: unset;
                            
                            .MuiTypography-root{
                                font-family: GillSans;
                                font-size: 0.9em;
                                color: $white;
                                &:hover{
                                    color: $red_semi;
                                }
                                
                            }
                            &.active{
                                .MuiTypography-root{
                                    font-family: GillSans-Bold;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
            .list_container2{
                margin: 20px 40px;
                @media screen and (max-width: 670px) {
                    display: block;
                    margin: 20px auto;
                }
                .list_title{
                    font-family: GillSans-Medium;
                    font-size: 0.9em;
                    color: $red_semi;
                    @media screen and (max-width: 670px) {
                        text-align: center;
                    }
                }
                .MuiList-root{
                    .MuiListItem-root{
                        padding: 1px;
                        flex-grow: unset;
                         -webkit-flex-grow: unset;
                        @media screen and (max-width: 670px) {
                            justify-content: center;
                        }
                        .MuiListItemText-root{
                            margin: 0;
                            -webkit-flex: unset;
                            flex: unset;
    
                            .MuiTypography-root{
                                font-family: GillSans;
                                font-size: 0.9em;
                                color: $white;
                                &:hover{
                                    color: $red_semi;
                                }
                                
                            }
                            &.active{
                                .MuiTypography-root{
                                    font-family: GillSans-Bold;
                                    color: $red_semi;
                                }
                            }
                        }
                    }
                }
            }
        }
        .copyright_container{
            display: flex;
            width: 100%;
            justify-content: center;
            padding-right: 20px;
            padding-bottom: 15px ;
            .copy_text{
                font-family: GillSans;
                font-size: 0.9em;
                color: $white;
                // margin-left: 80px;
                @media screen and (max-width: 768px) {
                    margin-left: 35px;
                }
                @media screen and (max-width: 576px) {
                    margin-left: 30px;
                }
            }
            .MuiList-root{
                display: flex;
                margin-right: 80px;
                padding: 0%;
                width: 250px;
                @media screen and (max-width: 768px) {
                    margin-right: 30px;
                }
                @media screen and (max-width: 576px) {
                    margin-right: 20px;
                }
                .MuiListItem-root{
                    padding: 1px;
                    flex-grow: unset;
                     -webkit-flex-grow: unset;
                    .MuiListItemText-root{
                        margin: 0;
                        -webkit-flex: unset;
                        flex: unset;
                        .MuiTypography-root{
                            font-family: GillSans;
                            font-size: 0.8em;
                            color: $grey;
                            &:hover{
                                color: $black;
                            }
                        }
                        &.active{
                            .MuiTypography-root{
                                font-family: GillSans-Medium;
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
}