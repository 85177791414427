@font-face {
    font-family: "GillSans-Bold";
    src: url("./Gill Sans Bold.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-BoldItalic";
    src: url("./Gill Sans Bold Italic.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-Bold";
    src: url("./Gill Sans Bold.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-Heavy";
    src: url("./Gill Sans Heavy.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-HeavyItalic";
    src: url("./Gill Sans Heavy Italic.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-Italic";
    src: url("./Gill Sans Italic.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-Light";
    src: url("./Gill Sans Light.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-LightItalic";
    src: url("./Gill Sans Light Italic.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-Medium";
    src: url("./Gill Sans Medium.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-MediumItalic";
    src: url("./Gill Sans Medium Italic.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans";
    src: url("./Gill Sans.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-CondensedBold";
    src: url("./GillSans Condensed Bold.otf") format("opentype"),
}
@font-face {
    font-family: "GillSans-Condensed";
    src: url("./GillSans Condensed.otf") format("opentype"),
}